import React from "react"
import Layout from "../components/App/Layout"
import SEO from "../components/App/SEO"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { graphql, StaticQuery } from "gatsby"
import StandaloneCard from "../components/BlogContent/StandaloneCard"

const StandalonePage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query StandaloneIndexQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "standalone" } } }
        ) {
          edges {
            node {
              id
              html
              excerpt
              frontmatter {
                publishToProd
                templateKey
                path
                title
                metaTitle
                date
                image
                blogTitle
                description
                metaDescription
              }
            }
          }
        }
        site {
          siteMetadata {
            title
            env
            imagesPrefix
          }
        }
      }
    `}
    render={data => (
      <Layout location={location} env={data.site.siteMetadata.env}>
        <Navbar />
        <SEO
          postTitle="Standalone | BlueMail App"
          postDescription="Everything new about Blue Mail - Redefining Your Email"
          postImage="/img/OG/og_image_Blog.png"
          postURL="standalone-blog"
          postSEO
        />
        <div className="post-feed-container bg-nav">
          <StandaloneCard
            data={data.allMarkdownRemark.edges.sort(
              (a, b) =>
                new Date(b.node.frontmatter.date) -
                new Date(a.node.frontmatter.date)
            )}
          />
        </div>
        <Footer />
      </Layout>
    )}
  />
)

export default StandalonePage