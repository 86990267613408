import React from "react"
import { Link } from "gatsby"

const StandaloneCard = ({ data }) => {
  return (
    <div className="blog-area pb-120">
      <div className="container">
        <div className="row">
          {data.map(post => (
            <div key={post.node.id} className="col-lg-4 col-md-6">
              {post.node.frontmatter.publishToProd && <div className="single-blog-post bg-fffbf5">
                <div className="post-image">
                  <Link to={`/${post.node.frontmatter.path}`}>
                    <img
                      src={post.node.frontmatter.image}
                      alt={`BlueMail ${post.node.frontmatter.blogTitle}`}
                    />
                  </Link>
                </div>

                <div className="post-content">
                  <h3>
                    <Link to={`/${post.node.frontmatter.path}`}>
                      {post.node.frontmatter.blogTitle}
                    </Link>
                  </h3>
                </div>
              </div>
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StandaloneCard
